const $ = require("jquery");
const Chart = require("chart.js");
const _ = require("lodash");
require("./numberformat");

class Artist {
    constructor(artistid) {
        this.artistid = artistid;
    }

    drawChart(data) {
        const years = [];
        const year_data = {};
        data.forEach(d => {
            year_data[d.yr] = d;
            years.push(d.yr);
        })
        $(function () {
            var ctx = document.getElementById('myChart').getContext('2d');
            new Chart(ctx, {
                type: 'line',
                data: {
                    labels: data.map(d => d.yr),
                    datasets: [
                        {
                            label: 'Maximum',
                            data: data.map(d => d.maximum),
                            borderColor: "rgba(54, 162, 235, 1)",
                            backgroundColor: "rgba(54, 162, 235, 0.2)"
                        },
                        {
                            label: 'Average',
                            data: data.map(d => d.average),
                            borderColor: "rgba(255, 99, 132, 1)",
                            backgroundColor: "rgba(255, 99, 132, 0.2)"
                        },
                        {
                            label: 'Minimum',
                            data: data.map(d => d.minimum),
                            borderColor: "rgba(255, 206, 86, 1)",
                            backgroundColor: "rgba(255, 206, 86, 0.2)"
                        }
                ]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                callback: function (value, index, values) {
                                    return "R" + value.format();
                                }
                            }
                        }]
                    }
                }
            });
        });
    }

    popup() {
        $(function() {
            const modalEl = $("#artworkModal");
            const artworkTemplate = _.template($("#artworkTemplate").html());
            $(document).on("click", "#paintings > tbody > tr",  e => {
                modalEl.find(".modal-title").html("");
                modalEl.find(".modal-body").html(`<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>`);
                modalEl.modal("show");
                const id = $(e.currentTarget).data("id");
                const title = $(e.currentTarget).data("title");
                $.get("/artwork/show/" + id)
                .then(json => {
                    const data = JSON.parse(json);
                    modalEl.find(".modal-body").html(artworkTemplate(data));
                    modalEl.find(".modal-title").html(title);
                });
            })
        })
    }

    medium() {
        const self = this;
        $(function () {
            $("#media_dropdown").change(function () {
                var selected = $("#media_dropdown option:selected");
                if (selected.val() != 0) {
                    window.location = `/artist/medium/${selected.val() }/${ self.artistid }`;
                } else {
                    window.location = `/artist/show/${ self.artistid }`;
                }
            });
        });
    }
}

module.exports = Artist;