const $ = require("jquery");
const Chart = require("chart.js");
const _ = require("lodash");
require("./numberformat");

class Artist {
    constructor() {}

    drawYearChart(el, title, data, color, bgColor, type, isCurrency) {
        const years = [];
        const year_data = [];
        data.forEach(d => {
            year_data.push(d[1]);
            years.push(d[0]);
        })
        $(function () {
            var ctx = document.getElementById(el).getContext('2d');
            new Chart(ctx, {
                type: type || 'line',
                data: {
                    labels: years,
                    datasets: [
                        {
                            label: title,
                            data: year_data,
                            borderColor: color,
                            backgroundColor: bgColor
                        }
                    ]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                callback: function (value, index, values) {
                                    if (isCurrency) {
                                        return "R" + value.format();
                                    }
                                    return value.format();
                                }
                            }
                        }]
                    }
                }
            });
        });
    }
}

module.exports = Artist;